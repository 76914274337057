const Button = ({ disabled, children, type = 'submit' }) => {
  return (
    <button
      className="rounded-md bg-cyan-500 hover:bg-cyan-600 font-medium text-white my-2 p-2"
      type={type}
      disabled={disabled}
    >
      {children}
    </button>
  );
};

export { Button };
