const rights = [
  {
    name: 'confirm',
    label: 'Right to Confirm',
  },
  {
    name: 'access',
    label: 'Right to Request Access (Data Portability)',
  },
  {
    name: 'deletion',
    label: 'Right to Request Deletion',
  },
  {
    name: 'correction',
    label: 'Right to Request Correction',
  },
  {
    name: 'withdrawConsent',
    label: 'Right to Withdraw Consent (Where consent was the basis for collection or processing)',
  },
  {
    name: 'optOutAdvertising',
    label: 'Right to Opt-Out of Targeted Advertising',
  },
  {
    name: 'optOutSale',
    label: 'Right to Opt-Out of Sale',
  },
  {
    name: 'optOutProfiling',
    label:
      'Right to Opt-Out of Automated Profiling in Furtherance of Legally Significant or Similar Decisions',
  },
  {
    name: 'appeal',
    label: 'Right to Appeal',
  },
];

export { rights };
