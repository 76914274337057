import { Navigate, Route, Routes } from 'react-router-dom';
import { Stepper } from '../components/Stepper/Stepper';

const AppRoutes = () => {
  return (
    <Routes>
      <Route exact path="/" element={<Stepper isInternal={false} />} />
      <Route exact path="/internal" element={<Stepper isInternal={true} />} />
      {/*fallback*/}
      <Route exact path="*" element={<Navigate to="/" />} />
    </Routes>
  );
};

export { AppRoutes };
