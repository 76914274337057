import { Field } from 'formik';
import { FieldError } from './FieldError';

const TextInput = ({ fieldName, label, placeholder = null, type = 'input', required = true }) => {
  return (
    <div className="mb-4">
      <label htmlFor={fieldName} className="font-medium text-gray-900">
        {label}
        {required && <span className="text-red-500 font-bold"> *</span>}
      </label>
      <Field
        as={type}
        name={fieldName}
        id={fieldName}
        className="rounded-md border-2 p-2 w-full"
        placeholder={placeholder || `Enter Your ${label}`}
        autoComplete="off"
      />
      <FieldError fieldName={fieldName} />
    </div>
  );
};

export { TextInput };
