const DocumentWrapper = ({ children }) => {
  return (
    <div className="flex items-center justify-center Xh-2/4">
      <div className="policy-container bg-gray-200 p-4 rounded-md shadow max-w-3xl w-full">
        <div className="policy-content overflow-y-auto max-h-96">
          <div className="bg-white p-5 privacy-text">{children}</div>
        </div>
      </div>
    </div>
  );
};

export { DocumentWrapper };
