import { useState } from 'react';
import { InternalPolicyDocument } from '../InternalPolicyDocument';
import { ExternalPolicyDocument } from '../ExternalPolicyDocument';
import { ThankYouPage } from '../ThankYouPage';

const steps = {
  POLICY: 'policy',
  THANKS: 'thanks',
};

const Stepper = ({ isInternal = false }) => {
  const [activeStep, setActiveStep] = useState(steps.POLICY);

  const PolicyDocument = isInternal ? InternalPolicyDocument : ExternalPolicyDocument;

  return (
    <div className="h-screen flex flex-col items-center justify-start">
      {activeStep === steps.POLICY && <PolicyDocument next={() => setActiveStep(steps.THANKS)} />}
      {activeStep === steps.THANKS && <ThankYouPage />}
    </div>
  );
};

export { Stepper };
