import { InternalPrivacyPolicy } from '../documents';
import { CustomerDetails } from './CustomerDetails/CustomerDetails';
import { DocumentWrapper } from './DocumentWrapper';

const InternalPolicyDocument = ({ next }) => {
  return (
    <>
      <DocumentWrapper>
        <InternalPrivacyPolicy />
      </DocumentWrapper>
      <CustomerDetails onComplete={next} isInternal={true} withRecaptcha={false} />
    </>
  );
};

export { InternalPolicyDocument };
