const ThankYouPage = () => {
  return (
    <div className="bg-white p-8 rounded-lg shadow-lg mt-12">
      <p className="text-gray-700 mb-4">
        Thank you for your submission. A member of the data privacy team will contact you about your
        request if there are any questions.
      </p>
      <p className="text-gray-700 mb-4">Personal privacy is important to our team.</p>
    </div>
  );
};

export { ThankYouPage };
