import { Field } from 'formik';
import InputMask from 'react-input-mask';
import { FieldError } from './FieldError';

const PhoneInput = ({ fieldName, label, required = true }) => {
  return (
    <div className="mb-4">
      <label htmlFor={fieldName} className="font-medium text-gray-900">
        {label}
        {required && <span className="text-red-500 font-bold"> *</span>}
      </label>
      <Field name={fieldName}>
        {({ field }) => (
          <InputMask
            {...field}
            id={fieldName}
            className="rounded-md border-2 p-2 w-full"
            mask="(999) 999-9999"
            maskChar=" "
          />
        )}
      </Field>
      <FieldError fieldName={fieldName} />
    </div>
  );
};

export { PhoneInput };
