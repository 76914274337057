import { ExternalPrivacyPolicy } from '../documents';
import { CustomerDetails } from './CustomerDetails/CustomerDetails';
import { DocumentWrapper } from './DocumentWrapper';

const ExternalPolicyDocument = ({ next }) => {
  return (
    <>
      <DocumentWrapper>
        <ExternalPrivacyPolicy />
      </DocumentWrapper>
      <CustomerDetails onComplete={next} isInternal={false} withRecaptcha={true} />
    </>
  );
};

export { ExternalPolicyDocument };
